import React from 'react';
import { render } from 'react-dom';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from './theme';




import { GlobalStyle } from './components/GlobalStyle';
import Box from './layouts/Box';
import { Flex, Stack, Row } from './layouts/Flex';
import { Text, Heading } from './components/Text';










export function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />


      <Stack p={12} gap={3}>
        <Box p={4} bg={'red'} />
        <Box p={12} bg={'blue'} />
        <Box p={4} bg={'red'} />
        <Box p={4} bg={'red'} />
        <Box p={4} bg={'purple'} />
        <Box p={4} bg={'red'} />
        <Box p={8} bg={'red'} />
      </Stack>


      <Box p={5}>
        <Stack gap={3}>
          <Heading Size={8}>Text 5XL  </Heading>
          <Heading Size={7}>Text 4XL  </Heading>
          <Heading Size={6}>Text 3XL  </Heading>
          <Heading Size={5}>Text 2XL  </Heading>
          <Heading Size={4}>Text XL   </Heading>

          <Text Size={3}>Text LG   </Text>
          <Text Size={2}>Text Base </Text>
          <Text Size={1}>Text SM   </Text>
          <Text Size={0}>Text XS   </Text>
        </Stack>


        {/* <Row gap={3}>
          <Text Size={5}>Text 2XL</Text>
          <Text Size={4}>Text XL</Text>
          <Text Size={3}>Text LG </Text>
          <Text Size={2}>Text base </Text>
          <Text Size={1}>Text SM </Text>
          <Text Size={0}>Text XS </Text>
        </Row> */}
      </Box>


    </ThemeProvider>
  );

}