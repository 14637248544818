

export const theme = {
  // space: [0, 4, 8, 16, 24, 32, 48, 64, 96, 128, 172, 256, 512],
  space: [
    'clamp(0.307rem, 0.147vw + 0.271rem, 0.435rem)',
    'clamp(0.384rem, 0.225vw + 0.328rem, 0.581rem)',
    'clamp(0.48rem, 0.336vw + 0.396rem, 0.774rem)',
    'clamp(0.6rem, 0.493vw + 0.477rem, 1.032rem)',
    'clamp(0.75rem, 0.714vw + 0.571rem, 1.375rem)',
    'clamp(0.938rem, 1.023vw + 0.682rem, 1.833rem)',
    'clamp(1.172rem, 1.453vw + 0.809rem, 2.443rem)',
    'clamp(1.465rem, 2.048vw + 0.953rem, 3.257rem)',
    'clamp(1.831rem, 2.869vw + 1.114rem, 4.341rem)',
    'clamp(2.289rem, 3.998vw + 1.289rem, 5.787rem)',
    'clamp(2.861rem, 5.546vw + 1.474rem, 7.714rem)',
    'clamp(3.576rem, 7.665vw + 1.66rem, 10.283rem)',
    'clamp(4.47rem, 10.556vw + 1.831rem, 13.707rem)',
  ],
  fonts: {
    body: '"Inter Tight", system-ui, -apple-system, BlinkMacSystemFont',
    heading: '"Inter Tight", system-ui, -apple-system, BlinkMacSystemFont',
  },
  fontSizes: [
    'clamp(0.64rem, 0.03vw + 0.63rem, 0.67rem)',
    'clamp(0.8rem, 0.1vw + 0.77rem, 0.89rem)',
    'clamp(1rem, 0.21vw + 0.95rem, 1.19rem)',
    'clamp(1.25rem, 0.38vw + 1.15rem, 1.58rem)',
    'clamp(1.56rem, 0.63vw + 1.41rem, 2.11rem)',
    'clamp(1.95rem, 0.98vw + 1.71rem, 2.81rem)',
    'clamp(2.44rem, 1.49vw + 2.07rem, 3.75rem)',
    'clamp(3.05rem, 2.22vw + 2.5rem, 5rem)',
    'clamp(3.81rem, 3.25vw + 3rem, 6.66rem)',
  ],
  letterSpacings: {
    body: '0.01em',
    heading: '0em',
  },
  fontWeights: {
    body: 500,
    heading: 600
  },
  lineHeights: {
    body: 1.35,
    heading: 1.05,
  },
  colors: {
    heading: '#ccc',
    text: '#aaa',
    background: '#1b1e23',
    primary: '#ebebec',
    secondary: '#808080',
    muted: '#393a3d',
    hinted: '#2a2c30',
    red: '#f07071',
    orange: '#ea9755',
    yellow: '#d4c05e',
    green: '#7eb36a',
    teal: '#64b9c4',
    blue: '#85a2f7',
    purple: '#bc85d9',
    pink: '#e587b6',
    grey: '#a9b4c4',
    modes: {
      light: {
        heading: '#1b1e23',
        text: '#333',
        background: '#FFFFFF',
        primary: '#1b1e23',
        secondary: '#808080',
        muted: '#b9b9bb',
        hinted: '#f2f2f1',
        red: '#f07071',
        orange: '#ea9755',
        yellow: '#d4c05e',
        green: '#7eb36a',
        teal: '#64b9c4',
        blue: '#85a2f7',
        purple: '#bc85d9',
        pink: '#e587b6',
        grey: '#a9b4c4',
      },
    },
  },










  // styles: {
  //   root: {
  //     fontFamily: 'body',
  //     lineHeight: 'body',
  //     fontWeight: 'body',
  //     letterSpacing: 'body',
  //   },
  //   a: {
  //     color: 'text',
  //     '@media (hover: hover) and (pointer: fine)': {
  //       '&:active': {
  //         color: 'text',
  //       },
  //       '&:hover': {
  //         color: 'secondary',
  //       },
  //     },
  //     textDecoration: 'underline',
  //     cursor: 'pointer',
  //   },
  //   hr: {
  //     border: 'none',
  //     borderStyle: 'solid',
  //     borderWidth: '0px',
  //     borderTopWidth: '1px',
  //     borderColor: 'muted',
  //   },
  //   p: {
  //     fontSize: 3,
  //     lineHeight: 'body',
  //     fontFamily: 'body',
  //     fontWeight: 'body',
  //     letterSpacing: 'body',
  //     my: ['1em'],
  //   },
  //   h1: {
  //     fontSize: [6, 6, 7, 8],
  //     fontFamily: 'heading',
  //     letterSpacing: 'heading',
  //     fontWeight: 'heading',
  //     lineHeight: 'h1',
  //     mt: [5, 7, 7],
  //     mb: [5, 6, 6],
  //   },
  //   h2: {
  //     fontSize: [5, 5, 5, 6],
  //     fontFamily: 'heading',
  //     fontWeight: 'heading',
  //     lineHeight: 'h2',
  //     mt: [5, 6, 6],
  //     mb: [4, 5, 5],
  //   },
  //   h3: {
  //     fontSize: [4, 4, 4, 5],
  //     fontFamily: 'heading',
  //     fontWeight: 'heading',
  //     lineHeight: 'h3',
  //     mt: [4, 5, 5],
  //     mb: [3, 4, 4],
  //   },
  //   h4: {
  //     fontSize: [4, 4, 4, 5],
  //     fontFamily: 'heading',
  //     fontWeight: 'heading',
  //     lineHeight: 'h3',
  //     mt: [4, 5, 5],
  //     mb: [3, 4, 4],
  //   },
  //   h5: {
  //     fontSize: [4, 4, 4, 5],
  //     fontFamily: 'heading',
  //     fontWeight: 'heading',
  //     lineHeight: 'h3',
  //     mt: [4, 5, 5],
  //     mb: [3, 4, 4],
  //   },
  //   h6: {
  //     fontSize: [4, 4, 4, 5],
  //     fontFamily: 'heading',
  //     fontWeight: 'heading',
  //     lineHeight: 'h3',
  //     mt: [4, 5, 5],
  //     mb: [3, 4, 4],
  //   },
  //   blockquote: {
  //     bg: 'hinted',
  //     color: 'primary',
  //     fontFamily: 'body',
  //     lineHeight: '1.35',
  //     fontSize: [3, 3, 3, 4],
  //     borderRadius: '1px',
  //     mx: [0],
  //     my: [4, 4, 4, 5],
  //     p: [3, 3, 3, 4],
  //     '> p': {
  //       m: [0],
  //     },
  //   },
  //   ul: {
  //     paddingInlineStart: ['20px', '20px', '20px', '24px'],
  //     marginBlockStart: [-2, -2, -2, -3],
  //     fontSize: [3, 3, 3, 4],
  //   },
  //   ol: {
  //     paddingInlineStart: ['26px', '26px', '26px', '42px'],
  //     marginBlockStart: [-2, -2, -2, -3],
  //     fontSize: [3, 3, 3, 4],
  //   },
  //   thematicBreak: {
  //     mt: [6, 6, 7, 7],
  //   },
  //   pre: {
  //     p: [3, 3, 3, 4],
  //     fontSize: [1, 1, 1, 2],
  //     fontFamily: 'mono',
  //     letterSpacing: 'mono',
  //     borderRadius: '1px',
  //     overflowWrap: 'break-word',
  //     wordWrap: 'break-word',
  //     wordBreak: 'break-word',
  //     whiteSpace: 'break-spaces',
  //     color: 'primary',
  //     bg: 'hinted',
  //     '.comment,.prolog,.doctype,.cdata,.punctuation,.operator,.entity,.url': {
  //       color: 'secondary',
  //     },
  //     '.property,.boolean,.deleted,.class-name,.variable': {
  //       color: 'primary',
  //     },
  //     '.function,.boolean,.number,.constant,.symbol,.class-name': {
  //       color: 'primary',
  //     },
  //     '.atrule, .attr-value, .keyword': {
  //       color: 'primary',
  //     },
  //     '.selector, .string, .char, .inserted': {
  //       color: 'secondary',
  //     },
  //   },
  //   code: {
  //     px: [1],
  //     pb: ['3px'],
  //     pt: ['1px'],
  //     mx: ['1px'],
  //     fontSize: [1, 1, 1, 2],
  //     fontFamily: 'mono',
  //     letterSpacing: 'mono',
  //     bg: 'hinted',
  //     color: 'primary',
  //     borderRadius: '1px',
  //   },
  // },
  // breakpoints: ['40em', '64em', '102em'],
  config: {
    initialColorModeName: 'dark',
    printColorModeName: 'light',
    useColorSchemeMediaQuery: false,
  },
}