import styled from 'styled-components'
import { compose, system, space, layout, flexbox, grid } from 'styled-system'
import { theme } from '../theme';



const Flex = styled.div`
  display: flex;

  ${compose(
    space,
    layout,
    flexbox
  )}

`



const Stack = styled(Flex)`
  flex-direction: column;

  ${system({
    gap: {
      property: 'gap',
      scale: 'space',
    },
    xAlign: {
      property: 'align-items',
      scale: 'align-items',
    },
    yAlign: {
      property: 'justify-content',
      scale: 'justify-content',
    }
  })}
`


const Row = styled(Flex)`
  flex-wrap: wrap;

  ${system({
    gap: {
      property: 'gap',
      scale: 'space',
    },
    xAlign: {
      property: 'justify-content',
      scale: 'justify-content',
    },
    yAlign: {
      property: 'align-items',
      scale: 'align-items',
    }
  })}
`




export { Flex, Stack, Row }