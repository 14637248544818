import styled from 'styled-components'
import { system, compose, space, color, typography } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'



const Text = styled.div`
  color: ${themeGet('colors.text', ' ')};
  font-family: ${themeGet('fonts.body', ' ')};
  font-weight: ${themeGet('fontWeights.body', '400')};
  letter-spacing: ${themeGet('letterSpacings.body', '0')};
  line-height: ${themeGet('lineHeights.body', '0')};

  ${system({
    Size: {
      property: 'font-size',
      scale: 'fontSizes',
    },
  })}

  ${compose(
    space,
    typography,
    color,
  )}

`





const Heading = styled(Text)`
  color: ${themeGet('colors.heading', ' ')};
  font-family: ${themeGet('fonts.heading', ' ')};
  font-weight: ${themeGet('fontWeights.heading', '400')};
  letter-spacing: ${themeGet('letterSpacings.heading', '0')};
  line-height: ${themeGet('lineHeights.heading', '0')};
`




// const Heading = styled(Text)`
//   color: ${theme.colors.heading};
//   font-weight: ${theme.fontWeights.heading};
//   font-family: ${theme.fonts.heading};
//   line-height: ${theme.lineHeights.heading};
//   letter-spacing: ${theme.letterSpacings.heading};
// `


// const Heading = styled(Text)`
//   font-weight: ${theme.fontWeights.heading};
//   letter-spacing: ${theme.letterSpacings.heading};
// `
// const Paragraph = styled(Text)`
//   font-weight: ${theme.fontWeights.body};
//   letter-spacing: ${theme.letterSpacings.body};

//   font-size: ${props => 
//     (props.Large && theme.fontSizes[3]) ||
//     (props.Small && theme.fontSizes[1]) ||
//     (props.Tiny && theme.fontSizes[0]) ||
//     theme.fontSizes[2]
//   };
// `


export { Text, Heading }