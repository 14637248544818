import styled from 'styled-components'
import { compose, space, color, layout, flexbox, grid } from 'styled-system'


const Box = styled.div`
  box-sizing: border-box;

  ${compose(
    space,
    color,
    layout,
    flexbox,
    grid
  )}

`




export default Box