import styled, { createGlobalStyle } from 'styled-components';
import { theme } from '../theme';



// theme.space = theme.space.map((value) => {
//   if (value.toString().includes('px')) { return value; }
//   else { return `${value}px`; }
// });



// theme.fontSizes = theme.fontSizes.map((value) => {
//   if (value.toString().includes('px')) { return value; }
//   else { return `${value}px`; }
// });




// theme.space = theme.space.map((value) => {
//   if (value.toString().includes('px')) {
//     return value;
//   } else {
//     return `${value}px`;
//   }
// });
// theme.fontSizes = theme.fontSizes.map((value) => {
//   if (value.toString().includes('px')) {
//     return value;
//   } else {
//     return `${value}px`;
//   }
// });





// text.small =  theme.fontSizes[1];
// text.medium = theme.fontSizes[2];
// text.large =  theme.fontSizes[3];





const GlobalStyle = createGlobalStyle`

/* ------------------------- Reset -------------------------------------------------- */
  html{line-height:1.15;-webkit-text-size-adjust:100%}body{margin:0}main{display:block}h1{font-size:2em;margin:.67em 0}hr{box-sizing:content-box;height:0;overflow:visible}pre{font-family:monospace,monospace;font-size:1em}a{background-color:transparent}abbr[title]{border-bottom:none;text-decoration:underline;text-decoration:underline dotted}b,strong{font-weight:bolder}code,kbd,samp{font-family:monospace,monospace;font-size:1em}small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}sub{bottom:-.25em}sup{top:-.5em}img{border-style:none}button,input,optgroup,select,textarea{font-family:inherit;font-size:100%;line-height:1.15;margin:0}button,input{overflow:visible}button,select{text-transform:none}[type=button],[type=reset],[type=submit],button{-webkit-appearance:button}[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner{border-style:none;padding:0}[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring{outline:1px dotted ButtonText}fieldset{padding:.35em .75em .625em}legend{box-sizing:border-box;color:inherit;display:table;max-width:100%;padding:0;white-space:normal}progress{vertical-align:baseline}textarea{overflow:auto}[type=checkbox],[type=radio]{box-sizing:border-box;padding:0}[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}[type=search]{-webkit-appearance:textfield;outline-offset:-2px}[type=search]::-webkit-search-decoration{-webkit-appearance:none}::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}details{display:block}summary{display:list-item}template{display:none}[hidden]{display:none}



/* ------------------------- Fonts -------------------------------------------------- */
 

/* ------------------------- Colors -------------------------------------------------- */




/* ------------------------- Body -------------------------------------------------- */

  *, *:after, *:before { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
  li, ul {padding: 0; margin: 0; list-style: none;}

  body {
    -webkit-font-smoothing: antialiased;
    font-family: ${theme.fonts.body};
    font-size: 16px;
    color: ${theme.colors.text};
    font-weight: ${theme.fontWeights.body};
    line-height: ${theme.lineHeights.body};
    letter-spacing: ${theme.letterSpacings.body};
    background-color: ${theme.colors.background};
  }

  html, body {
    width: 100%;
    -webkit-font-smoothing: antialiased;
    position: relative;
    background-color: ${theme.colors.background};
  }

  canvas, img, video {
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
  } 

  svg {
    max-width: none;
    height: auto;
    box-sizing: border-box;
  }

  audio, canvas, iframe, img, svg, video {
    vertical-align: middle;
  }



  /* ------------------------- Typography -------------------------------------------------- */


  h1, h2, h3, h4, h5, h6, p, a, li, ul, ol, small, big, span, strong, em  {padding: 0; margin: 0; font-style: normal; font-weight: 400; letter-spacing: normal;}





  :root {
    font-size: 16px;
    
    // --font-size-xs: clamp(0.64rem, 0.03vw + 0.63rem, 0.67rem);
    // --font-size-sm: clamp(0.8rem, 0.1vw + 0.77rem, 0.89rem);
    // --font-size-base: clamp(1rem, 0.21vw + 0.95rem, 1.19rem);
    // --font-size-lg: clamp(1.25rem, 0.38vw + 1.15rem, 1.58rem);
    // --font-size-xl: clamp(1.56rem, 0.63vw + 1.41rem, 2.11rem);
    // --font-size-2xl: clamp(1.95rem, 0.98vw + 1.71rem, 2.81rem);
    // --font-size-3xl: clamp(2.44rem, 1.49vw + 2.07rem, 3.75rem);
    // --font-size-4xl: clamp(3.05rem, 2.22vw + 2.5rem, 5rem);
    // --font-size-5xl: clamp(3.81rem, 3.25vw + 3rem, 6.66rem);
  }

  
  
//   h1 {
//     font-size: var(--font-size-5xl);
//     line-height: 1.13;
//   }

// h2 {
//   font-size: var(--font-size-4xl);
//   line-height: 1.15;
// }

// h3 {
//   font-size: var(--font-size-3xl);
//   line-height: 1.25;
// }

// h4 {
//   font-size: var(--font-size-2xl);
//   line-height: 1.35;
// }

// h5 {
//   font-size: var(--font-size-xl);
//   line-height: 1.45;
// }



// big {
//   font-size: var(--font-size-lg);
//   line-height: 1.5;
// }

// p {
//   font-size: var(--font-size-base);
//   line-height: 1.5;
// }

// small {
//   font-size: var(--font-size-sm);
//   line-height: 1.5;
// }

// x-small {
//   font-size: var(--font-size-xs);
//   line-height: 1.5;
// }







`;






export { GlobalStyle }